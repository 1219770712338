<template>
  <div class="nodeContentFormFieldsTable">
    <el-table-draggable
      @drag="onDragStart"
      @drop="onDragEnd"
      handle=".action-icon"
      class="draggable"
    >
      <el-table
        class="form-rules"
        fit
        :data="nodeContentFormFields"
        style="width: 100%;"
        :row-class-name="generateUniqueRowClass"
        :show-header="false"
        :row-key="row => row.uuid"
        :expand-row-keys="expandRowKeys"
        @expand-change="handleExpandChange"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="form-field-expand-container">
              <div />
              <div class="form-field-expand-block">
                <div class="form-field-expand">
                  <el-form-item
                    class="form-field-expand--defaultvalue"
                    :label="__('Default Value')"
                    :error="hasInvalidVariable(scope.row.default_value)"
                    :class="classes(scope.row, 'default_value', scope.$index)"
                  >
                    <input-variable-popper
                      v-model="scope.row.default_value"
                      :is-text-area="false"
                      force-reinitialize
                      :placeholder="__('Enter Default Value')"
                      :include-secure-variables="false"
                    ></input-variable-popper>
                  </el-form-item>
                  <el-form-item
                    class="form-field-expand--placeholder no-red-highlight"
                    :label="__('Placeholder')"
                  >
                    <el-input
                      v-model="scope.row.field_placeholder"
                      :placeholder="__('Enter Placeholder')"
                    ></el-input>
                  </el-form-item>
                  <div class="form-field-expand--dummy-msg" />
                  <div class="form-field-expand--dummy-action">
                    <span>
                      <i class="el-icon-circle-close"></i>
                    </span>
                  </div>
                </div>
                <div class="form-field-expand" v-if="showFileUpload">
                  <el-form-item
                    class="form-field-expand--type no-red-highlight"
                    :label="__('Field Type')"
                  >
                    <el-select
                      v-model="scope.row.field_type"
                      :placeholder="__('Select Field Type')"
                      default-first-option
                    >
                      <el-option
                        v-for="item in formFieldTypes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item
                    v-if="fieldTypeHasSecondaryVar(scope.row.field_type)"
                    class="form-field-expand--secondary_variable no-red-highlight"
                  >
                    <create-or-select
                      class="form-field-item form-field-item--secondary_variable"
                      :items="singleValuedAndSecureVariables"
                      label="variable_name"
                      :form-item-label="
                        fieldTypeSecondaryVarLabel(scope.row.field_type)
                      "
                      :form-item-required="true"
                      value="variable_id"
                      :current_select="currentSecondarySelection(scope.row)"
                      :placeholder="__('Variable name')"
                      :new-item-message="__('new variable')"
                      @change="handleSecondaryChange($event)(scope.row)"
                      :class="
                        classes(
                          scope.row,
                          'secondary_variable_name',
                          scope.$index
                        )
                      "
                    />
                  </el-form-item>
                </div>
                <div class="form-field-expand">
                  <el-form-item
                    :label="__('Required')"
                    :error="scope.row.is_required_error"
                    class="form-field-expand--required no-red-highlight"
                  >
                    <el-switch
                      v-model="scope.row.is_required"
                      @change="handleRequiredChange($event)(scope.row)"
                    >
                    </el-switch>
                  </el-form-item>
                </div>
                <el-form-item
                  class="form-field-expand--description no-red-highlight"
                  :label="__('Description')"
                >
                  <el-input
                    width="100%"
                    v-model="scope.row.field_description"
                    :placeholder="__('Enter Description')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column style="width: 100%; padding-bottom: 3%">
          <template slot-scope="scope">
            <div class="form-field">
              <el-form-item
                :label="__('Title')"
                :error="scope.row.field_title_error"
                :required="true"
                class="form-field-item form-field-item--title"
                :class="classes(scope.row, 'field_title', scope.$index)"
              >
                <el-input
                  class="input-with-word-limit"
                  v-model="scope.row.field_title"
                  :placeholder="__('Enter Title')"
                  maxlength="200"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <create-or-select
                class="form-field-item form-field-item--variable"
                :items="singleValuedAndSecureVariables"
                label="variable_name"
                :form-item-label="__('Assign to Variable')"
                :form-item-required="true"
                value="variable_id"
                :current_select="currentSelection(scope.row)"
                :placeholder="__('Variable name')"
                :new-item-message="__('new variable')"
                @change="handleChange($event)(scope.row)"
                :class="classes(scope.row, 'variable_name', scope.$index)"
              />
              <div class="form-field-item form-field-item--msg">
                {{ scope.row.msg || "" }}
              </div>
              <div class="form-field-item form-field-item--action">
                <span @click="removeNodeContentFormField(scope)">
                  <i class="el-icon-circle-close"></i>
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-table-draggable>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import CreateOrSelect from "./CreateOrSelect";
import ElTableDraggable from "element-ui-el-table-draggable";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import { v4 as uuidv4 } from "uuid";
import VariableChecks from "@/views/mixins/VariableChecks";
import {
  VISUAL_FORM_FIELD_TYPES,
  VISUAL_FORM_FIELD_TYPE_CONST
} from "@/constants/visualFormFieldTypes.js";

export default {
  components: {
    CreateOrSelect,
    ElTableDraggable,
    InputVariablePopper
  },
  mixins: [VariableChecks],
  name: "node-content-form-fields",
  props: {
    value: {
      required: true,
      type: Array
    },
    nodeType: {
      required: false,
      default: "visual_form"
    },
    gotoOptions: {
      required: true,
      type: Array
    },
    gotoOptionsForPostback: {
      required: false,
      type: [Array, Boolean]
    }
  },
  data() {
    let nodeContentFormField = {
      uuid: uuidv4(),
      field_id: -1,
      field_title: "",
      field_placeholder: "",
      field_description: "",
      is_required: false,
      field_type: VISUAL_FORM_FIELD_TYPE_CONST.DEFAULT,
      variable_id: -1,
      variable_name: "",
      secondary_variable_id: -1,
      secondary_variable_name: "",
      default_value: ""
    };

    return {
      nodeContentFormField,
      $nodeContentFormFields: [{ ...nodeContentFormField }],
      $gotoOptions: [],
      expandRowKeys: [],
      invalidFormFieldIndex: [],
      showFileUpload: false
    };
  },
  computed: {
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit
    }),
    ...mapState("app", {
      selectedAccountId: "selectedAccountId"
    }),
    ...mapGetters("variables", {
      singleValuedAndSecureVariables: "singleValuedAndSecureVariables"
    }),

    /**
     * @typedef {Object} VisualFormFiledType
     * @property {string} label Label to be displayed
     * @property {string} value Internal representation of the field type
     */
    /**
     * Get the supported form field type
     * @returns {VisualFormFiledType[]}
     */
    formFieldTypes() {
      return VISUAL_FORM_FIELD_TYPES.map(type => {
        return {
          label: type.label,
          value: type.value
        };
      });
    },

    /**
     * get error class
     * @returns {(function(*, *): (string|undefined))|*}
     */
    classes() {
      return (row, key, index) => {
        if (
          index > 0 &&
          _.includes(["field_title", "variable_name"], key) &&
          !row.field_title &&
          !row.variable_name
        ) {
          return "empty-row empty-val";
        }
        let hasError;
        if (_.includes(["field_title", "variable_name"], key)) {
          hasError = !row[key] || this.hasInvalidVariable(row[key]);
        } else {
          hasError = this.hasInvalidVariable(row[key]);
        }
        if (!hasError) {
          return "no-red-highlight";
        }
      };
    },

    /**
     * has invalid variable
     */
    hasInvalidVariable() {
      return value => {
        let invalidVariables = this.invalidVariableIdsPresentInAValue(
          value,
          true
        );

        return !_.isEmpty(invalidVariables)
          ? __("invalid variable, ") + invalidVariables[0]
          : "";
      };
    },

    /**
     *
     * @returns {[{variable_id: number, variable_type: string, button_type: *, button_icon: string, default_value: string, variable_name: string, keyword: string, keyword_type: string}]}
     */
    nodeContentFormFields() {
      return this.$data.$nodeContentFormFields;
    },

    /**
     * get the current variable selected
     * @returns {(function(*): (*))|*}
     */
    currentSelection() {
      return row => {
        const { variable_id, variable_name } = row;
        return variable_id === -1 ? variable_name : variable_id;
      };
    },

    /**
     * get the current secondary variable selected
     * @returns {(function(*): (*))|*}
     */
    currentSecondarySelection() {
      return row => {
        let { secondary_variable_id, secondary_variable_name } = row;
        return secondary_variable_id === -1
          ? secondary_variable_name
          : secondary_variable_id;
      };
    }
  },
  methods: {
    /**
     * Check if the launch darkly flag for the visual form file upload is enabled
     * @returns { boolean } True if enabled. False otherwise
     */
    async fileUploadFlagEnabled() {
      this.showFileUpload = await this.showFeature(
        this.$getConst("VISUAL_FORM_FILE_UPLOAD")
      );
    },
    /**
     * Get the field type detail
     * @param {string} fieldType
     * @returns {VisualFormFieldTypeDetail}
     */
    getFieldTypeDetail(fieldType) {
      const type = VISUAL_FORM_FIELD_TYPES.filter(
        type => type.value === fieldType
      ).pop();
      return type;
    },

    /**
     * Check if the selected field type supports secondary variable
     * @param {string} fieldType
     * @returns {boolean} True if the selected field type supports secondary variable
     */
    fieldTypeHasSecondaryVar(fieldType) {
      return (
        _.get(this.getFieldTypeDetail(fieldType), "hasSecondaryVar") === true
      );
    },

    /**
     * Get the field type's secondary variable label
     * @param {string} fieldType
     * @returns {string}
     */
    fieldTypeSecondaryVarLabel(fieldType) {
      return _.get(
        this.getFieldTypeDetail(fieldType),
        "secondaryVariableLabel"
      );
    },

    /**
     * generate a unique class for the node content button row
     * @param rowIndex
     * @returns {string}
     */
    generateUniqueRowClass({ rowIndex }) {
      let classNames = "row row-" + rowIndex;
      if (_.includes(this.invalidFormFieldIndex, rowIndex)) {
        classNames += " has-error";
      }
      return classNames;
    },

    /**
     * generate table row key
     * @param rowIndex
     * @returns {string}
     */
    generateTableRowKey({ rowIndex }) {
      return "row-" + rowIndex;
    },

    /**
     * add new variable rule
     */
    addNewNodeContentFormField() {
      const newNodeContentFormField = _.cloneDeep(this.nodeContentFormField);
      newNodeContentFormField.uuid = uuidv4();
      this.$data.$nodeContentFormFields.push({ ...newNodeContentFormField });
    },

    /**
     * remove variable rule
     * @param row
     * @param $index
     */
    removeNodeContentFormField({ row, $index }) {
      this.$data.$nodeContentFormFields.splice($index, 1);
      this.$emit("removed-msg-btn", _.get(row, "field_title"));
    },

    /**
     * initialise node content form fields
     * @param nodeContentFormFields
     */
    initializeNodeContentFormFields(nodeContentFormFields) {
      if (!this.isNodeSubmit) {
        if (!_.isEmpty(nodeContentFormFields) && nodeContentFormFields.length) {
          this.$data.$nodeContentFormFields = _.cloneDeep(
            nodeContentFormFields
          );
        }
        if (
          !_.some(
            this.$data.$nodeContentFormFields,
            nodeContentFormFields => !nodeContentFormFields.field_title.length
          )
        ) {
          this.addNewNodeContentFormField();
        }
      }
    },

    /**
     * drag start
     */
    onDragStart() {
      let selector = ".matchKeywordsTable";
      const tables = document.querySelectorAll(selector);

      tables[0].classList.add("grabbing");
    },

    /**
     * drag end
     */
    onDragEnd() {
      let selector = ".matchKeywordsTable";
      const tables = document.querySelectorAll(selector);

      if (tables[0].classList.contains("grabbing")) {
        tables[0].classList.remove("grabbing");
      }
    },

    /**
     * based on the option changed,
     * @param option
     * @returns {(function(*): void)|*}
     */
    handleChange(option) {
      return row => {
        this.$set(row, "variable_id", option.value);
        this.$set(row, "variable_name", option.label);
        this.$set(row, "msg", option.msg);
      };
    },

    /**
     * based on the secondary variable option changed,
     * @param option
     * @returns {(function(*): void)|*}
     */
    handleSecondaryChange(option) {
      return row => {
        this.$set(row, "secondary_variable_id", option.value);
        this.$set(row, "secondary_variable_name", option.label);
      };
    },

    handleRequiredChange(value) {
      return row => {
        this.$set(row, "is_required", value);
      };
    },

    /**
     * handle row expand
     * @param row
     * @param expandedRows
     */
    handleExpandChange(row, expandedRows) {
      this.expandRowKeys = _.map(expandedRows, "uuid");
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "initializeNodeContentFormFields"
    },
    selectedAccountId: {
      immediate: true,
      async handler(newVal) {
        if (newVal === "all") {
          this.showFileUpload = false;
        } else {
          await this.fileUploadFlagEnabled();
        }
      }
    },
    nodeContentFormFields: {
      handler: function(nodeContentFormFields) {
        const expandRowKeys = _.cloneDeep(this.expandRowKeys);
        this.$emit("input", _.cloneDeep(nodeContentFormFields));
        this.expandRowKeys = expandRowKeys;
        let invalidVariablesFound = _.some(
          nodeContentFormFields,
          nodeContentFormField =>
            this.hasInvalidVariable(nodeContentFormField.default_value)
        );
        this.invalidFormFieldIndex = _.map(
          _.keys(
            _.pickBy(
              nodeContentFormFields,
              nodeContentFormField =>
                this.hasInvalidVariable(nodeContentFormField.default_value) ||
                (!(
                  !nodeContentFormField.field_title &&
                  !nodeContentFormField.variable_name
                ) &&
                  (!nodeContentFormField.field_title ||
                    !nodeContentFormField.variable_name))
            )
          ),
          Number
        );
        this.$emit("invalid-variables", invalidVariablesFound);
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";

.nodeContentFormFieldsTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.nodeContentFormFieldsTable {
  ::v-deep .no-red-highlight .el-input__inner,
  ::v-deep .no-red-highlight .el-textarea__inner,
  ::v-deep .empty-row .el-input__inner,
  ::v-deep .empty-row .el-textarea__inner {
    border-color: $--border-color-base !important;

    &:focus {
      border-color: $--color-text-regular !important;
    }
  }
}

.input-with-word-limit {
  ::v-deep .el-input__inner {
    padding-right: 60px;
  }
}

.nodeContentFormFieldsTable ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.nodeContentFormFieldsTable ::v-deep .el-table::before {
  background-color: white !important;
}

.nodeContentFormFieldsTable ::v-deep .el-table__body-wrapper {
  margin-top: 10px;
}

.form-rules {
  ::v-deep tbody .row {
    td .cell {
      justify-content: center;
    }
  }

  ::v-deep tbody .row.has-error {
    td .cell .el-table__expand-icon {
      color: red;
    }
  }
}

.form-field {
  display: flex;
  width: 100%;
  gap: 3.33%;
  align-items: flex-end;

  ::v-deep .el-form-item__label {
    font-size: 14px;
    word-break: break-word;
  }

  & .form-field-item--title,
  & .form-field-item-variable {
    flex: 1 0 40%;
  }

  & .form-field-item--required {
    flex: 1 0 10%;
  }

  & .form-field-item--msg,
  & .form-field-item--action {
    padding-bottom: 7px;
  }

  & .form-field-item--action {
    text-align: right;
    cursor: pointer;
    opacity: 0;
  }

  & .form-field-item--msg {
    min-width: 68px;
    font-size: 0.75rem;
    color: $--color-success;
    margin-left: 10px;
  }

  &:hover {
    .form-field-item--action {
      opacity: 1;
    }
  }
}

.form-field-expand-container {
  display: flex;

  > div {
    width: 48px;
  }

  .form-field-expand-block {
    padding-left: 10px;
    padding-right: 10px;
    flex: 1;

    .form-field-expand--description {
      flex: 1 0 100%;
      padding-top: 5px;
      padding-bottom: 10px;
    }

    ::v-deep .el-form-item__label {
      font-size: 14px;
      word-break: break-word;
    }

    .form-field-expand {
      display: flex;
      width: 100%;
      gap: 3.33%;
      margin-bottom: 10px;

      .form-field-expand--type,
      .form-field-expand--secondary_variable,
      .form-field-expand--defaultvalue,
      .form-field-expand--placeholder {
        flex: 1 0 40%;
      }

      .form-field-expand--secondary_variable,
      .form-field-expand--placeholder {
        flex: 0 1 100%;
      }

      .form-field-expand--dummy-msg {
        min-width: 68px;
        margin-left: 10px;
      }

      .form-field-expand--dummy-action {
        visibility: hidden;
      }
    }
  }
}

.draggable .action-icon {
  cursor: grab;
}

.grabbing {
  cursor: grabbing !important;
}

.grabbing * {
  cursor: grabbing !important;
}

/* hack to remove bottom border appearing on form fields section*/
.el-table::before {
  height: 0 !important;
}
</style>
